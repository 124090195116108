import { FormInfoElement } from "src/components/UI/FormInfoElement/FormInfoElement";
import classes from "../PaymentOptions.module.css";
import { useLocaleContext } from "src/context/locale-context";

function OfflinePayment() {
  const { getStringRes } = useLocaleContext();

  return (
    <div className={classes.cash}>
      <ul>
        <li>{getStringRes("payment.options.print.invoice")}</li>
        <li>{getStringRes("payment.offline.visit.office.description")}</li>
      </ul>
      <FormInfoElement>
        <p>{`${getStringRes("prepayment.invoice.info")} ${getStringRes("payment.offline.form.info.description")}`}</p>
      </FormInfoElement>
    </div>
  );
}

export default OfflinePayment;
