import { nanoid } from "@reduxjs/toolkit";
import { Suspense, createContext, useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MODAL_TYPE_COMPONENT } from "src/constants/modal-definitions";

const ModalContext = createContext({
  closeModal: (type, callback) => {},
  openModal: (type, modalProps, callback) => {},
  clearModals: (callback) => {},
});

export const ModalContextManager = (props) => {
  const location = useLocation();
  const [modalStack, setStackState] = useState([]);

  const isModalTypeInStack = (stack, type) => {
    return stack.findIndex((m) => m.type === type) !== -1;
  };

  const addModalToStack = useCallback((type, modalProps) => {
    let success = true;
    setStackState((stack) => {
      if (!isModalTypeInStack(stack, type)) {
        return [
          ...stack,
          {
            id: nanoid(),
            type,
            props: modalProps,
            isClosing: false,
            onClose: modalProps?.onClose ? modalProps.onClose : () => {},
          },
        ];
      }
      success = false;
      return stack;
    });
    return success;
  }, []);

  const removeModalFromStack = (type) => {
    let success = true;
    setStackState((stack) => {
      const modalTypeIndex = stack.findIndex((m) => m.type === type);
      if (modalTypeIndex !== -1) {
        const newStack = stack.slice();
        newStack.splice(modalTypeIndex, 1);
        return newStack;
      }
      success = false;
      return stack;
    });
    return success;
  };

  const onCloseModal = useCallback((type, callback) => {
    setStackState((stack) => {
      if (isModalTypeInStack(stack, type)) {
        const stackCopy = stack.slice();
        const modalTypeIndex = stackCopy.findIndex((m) => m.type === type);
        stackCopy[modalTypeIndex].isClosing = true;
        return stackCopy;
      }
      return stack;
    });
    setTimeout(() => {
      const success = removeModalFromStack(type);
      if (success && callback) callback();
    }, 200);
  }, []);

  const onOpenModal = useCallback(
    (type, modalProps, callback) => {
      const success = addModalToStack(type, modalProps);
      if (success && callback) callback();
    },
    [addModalToStack]
  );

  const closeAllModals = useCallback((callback) => {
    setStackState((stack) => {
      return stack.map((m) => {
        return { ...m, isClosing: true };
      });
    });
    setTimeout(() => {
      let success = true;
      setStackState((stack) => {
        if (stack.length === 0) {
          success = false;
        }
        return [];
      });
      if (success && callback) callback();
    }, 200);
  }, []);

  useEffect(() => {
    return () => {
      setStackState([]);
    };
  }, [location]);

  return (
    <ModalContext.Provider
      value={{
        closeModal: onCloseModal,
        openModal: onOpenModal,
        clearModals: closeAllModals,
      }}>
      {modalStack.slice().map((modal, i) => {
        if (MODAL_TYPE_COMPONENT[modal.type].isStandalone && i > 0) {
          return null;
        } else {
          const ModalComponent = MODAL_TYPE_COMPONENT[modal.type].component;
          const modalProps = {
            ...modal.props,
            modalId: modal.id,
            order: i,
            disablePointerEvents: i !== modalStack.length - 1,
            onClose: (...args) => onCloseModal(modal.type, () => modal.onClose(...args)),
            state: { isClosing: modal.isClosing },
          };
          return (
            <Suspense key={modal.id}>
              <ModalComponent {...modalProps} />
            </Suspense>
          );
        }
      })}
      {props.children}
    </ModalContext.Provider>
  );
};

export default ModalContext;

export const useModalContext = () => useContext(ModalContext);
