import { CollapsibleAnswer } from "src/components/features/faq/CollapsibleAnswer";

export function FAQList({ listClassName, itemClassName, questions }) {
  return (
    <ul className={listClassName}>
      {questions.map((q) => (
        <li key={q.id} id={q.id}>
          <CollapsibleAnswer
            containerClass={itemClassName}
            question={q.question}
            answer={q.answer}
            isOpenOnInit={q.isOpen}
          />
        </li>
      ))}
    </ul>
  );
}
