import { nanoid } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";

// global scroll state
let activeScrollLocks = 0;
let initialScrollbarWidth = null;

export default function useDisableScrollbar() {
  const [identifier] = useState(nanoid());
  useEffect(() => {
    if (activeScrollLocks === 0) {
      // Calculate scrollbar width when the 1st modal is opened
      initialScrollbarWidth = hasVerticalScrollbar() ? getScrollbarWidth() : 0;
      document.documentElement.style.paddingRight = `${initialScrollbarWidth}px`;
      document.documentElement.classList.add(`noscroll-${identifier}`);
    }

    activeScrollLocks += 1;

    return () => {
      activeScrollLocks -= 1;

      if (activeScrollLocks === 0) {
        // Remove padding + enable scrolling when all target elements are closed
        document.documentElement.style.paddingRight = "";
        const noscrollClasses = Array.from(document.documentElement.classList).filter((el) =>
          el.startsWith("noscroll-")
        );
        document.documentElement.classList.remove(...noscrollClasses);
      }
    };
  }, [identifier]);

  function hasVerticalScrollbar() {
    return document.documentElement.scrollHeight > document.documentElement.clientHeight;
  }

  function getScrollbarWidth() {
    return window.innerWidth - document.documentElement.clientWidth;
  }
}
