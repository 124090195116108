import { useDeviceContext } from "src/context/device-context";
import classes from "./FormInput.module.css";

export function TextInput({ containerClass, inputClass, onChange, ...props }) {
  const { isMobile } = useDeviceContext();

  return (
    <div
      className={`theme-form-input-text ${containerClass || ""} ${
        classes[isMobile ? "mobile" : "desktop"]
      } ${classes.field} ${classes.simple}`}>
      <input
        {...props}
        style={props.readOnly || props.disabled ? { backgroundColor: "var(--ui-2)" } : {}}
        type={props.type || "text"}
        className={`${classes.input} ${inputClass || ""}`}
        onChange={onChange}
      />
    </div>
  );
}

export function SelectInput({
  options = [],

  containerClass,
  inputClass,
  onChange,
  ...props
}) {
  const { isMobile } = useDeviceContext();

  return (
    <div
      className={`theme-form-input-dropdown ${containerClass || ""} ${
        classes[isMobile ? "mobile" : "desktop"]
      } ${classes.dropdown}  ${classes.simple}`}>
      <select
        id={props.id}
        disabled={props.disabled || options.length === 0}
        name={props.name}
        required={props.required}
        defaultValue={props.defaultValue}
        value={props.value}
        onChange={onChange}
        autoComplete="off"
        className={`${classes.field} ${inputClass || ""}`}>
        {props.enableNoSelection && (
          <option className={classes.placeholder} value="">
            {props.placeholder || ""}
          </option>
        )}
        {options.map((option) => (
          <option className={classes.option} value={option.key} key={option.value}>
            {option.value}
          </option>
        ))}
      </select>
    </div>
  );
}

export function TextAreaInput({ containerClass, inputClass, onChange, ...props }) {
  const { isMobile } = useDeviceContext();

  return (
    <div
      className={`theme-form-input-text ${containerClass || ""} ${
        classes[isMobile ? "mobile" : "desktop"]
      } ${classes.field} ${classes.simple}`}>
      <textarea
        {...props}
        style={props.readOnly ? { backgroundColor: "var(--ui-2)" } : {}}
        type={props.type || "text"}
        className={`${classes.input} ${inputClass || ""}`}
        onChange={onChange}
      />
    </div>
  );
}
