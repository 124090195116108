import classes from "../PaymentOptions.module.css";
import { motion } from "framer-motion";
import { PaymentVendor } from "./PaymentVendor";
import { useDeviceContext } from "src/context/device-context";
import { useDispatch, useSelector } from "react-redux";
import { bookingActions, selectPaymentData } from "src/store/booking";
import { useEffect, useRef } from "react";

const MAX_COL_NUM = 5;

export function PaymentSubtypes({ options }) {
  const { isMobile } = useDeviceContext();
  const dispatch = useDispatch();
  const paymentData = useSelector(selectPaymentData);
  const activeVendor = paymentData.vendor;
  const paymentRef = useRef(null);

  const emptyGridFillers = new Array(MAX_COL_NUM - (options.length % MAX_COL_NUM))
    .fill(null)
    .map((o, i) => "filler" + i);

  useEffect(() => {
    if (paymentData.type && !activeVendor && options.length > 0) {
      dispatch(bookingActions.updatePaymentVendor(options[0].code));
    }
  }, [dispatch, options, paymentData.type, activeVendor]);

  const onSelectVendorHandler = (vendorCode) => {
    dispatch(bookingActions.updatePaymentVendor(vendorCode));
    if (isMobile) {
      console.log(paymentRef);
      paymentRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <motion.ul
      ref={paymentRef}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1, ease: "circIn" }}
      className={classes.subtypes}>
      {options.map((vendor) => (
        <PaymentVendor
          onSelect={() => onSelectVendorHandler(vendor.code)}
          vendor={vendor}
          isActive={activeVendor === vendor.code}
          key={vendor.code}
        />
      ))}
      {!isMobile && emptyGridFillers.map((f) => <li className={classes.slot} key={f}></li>)}
    </motion.ul>
  );
}
