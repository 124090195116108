import { localeApi } from "src/services/api";
import { getFaqTopicsPageDataFromResources } from "./FAQTopicsLoader";
import { isLangAbsent, redirectWithLang } from "./utils/loader-utils";

const FAQ_TOPIC_TITLE_PATTERN = "faq.topic.title_";
const FAQ_TOPIC_DESC_PATTERN = "faq.topic.description_";
const FAQ_TOPIC_ICON_PATTERN = "faq.topic.iconName_";

const FAQ_TOPIC_QUESTION_PATTERN = "faq.common.question.question_";
const FAQ_TOPIC_ANSWER_PATTERN = "faq.common.question.answer_";

export async function loader({ request }) {
  console.log("faqpage loader");
  if (isLangAbsent(request)) return redirectWithLang(request);

  const lang = new URL(request.url).searchParams.get("lang");
  const response = await localeApi.getFAQResources(lang).catch((err) => {
    console.error(err);
    if (err.response?.status === 404) return { data: {} };
  });
  const faqStringResources = response.data;

  // process string res into required data structure
  const topicKeys = [];
  const descriptionKeys = [];
  const questionKeys = [];
  const answerKeys = [];
  const iconKeys = [];
  Object.keys(faqStringResources).forEach((key) => {
    if (key.startsWith(FAQ_TOPIC_TITLE_PATTERN)) {
      topicKeys.push(key);
    } else if (key.startsWith(FAQ_TOPIC_DESC_PATTERN)) {
      descriptionKeys.push(key);
    } else if (key.startsWith(FAQ_TOPIC_QUESTION_PATTERN)) {
      questionKeys.push(key);
    } else if (key.startsWith(FAQ_TOPIC_ANSWER_PATTERN)) {
      answerKeys.push(key);
    } else if (key.startsWith(FAQ_TOPIC_ICON_PATTERN)) {
      iconKeys.push(key);
    }
  });

  const faqData = { categories: [], common: [], topics: [] };

  faqData.categories = topicKeys.map((tk) => {
    const topicId = tk.replace(FAQ_TOPIC_TITLE_PATTERN, "");
    const descKey = descriptionKeys.find(
      (dk) => dk.replace(FAQ_TOPIC_DESC_PATTERN, "") === topicId
    );
    const iconKey = iconKeys.find((ik) => ik.replace(FAQ_TOPIC_ICON_PATTERN, "") === topicId);
    return {
      id: topicId,
      path: topicId,
      title: faqStringResources[tk] || "",
      icon: faqStringResources[iconKey] || "",
      description: faqStringResources[descKey] || "",
    };
  });

  faqData.common = questionKeys
    .filter((qk) => Number.isInteger(Number(qk.split(FAQ_TOPIC_QUESTION_PATTERN)[1])))
    .map((qk) => {
      const questionNum = qk.split(FAQ_TOPIC_QUESTION_PATTERN)[1];
      const answerkey = answerKeys.find(
        (ak) => ak.split(FAQ_TOPIC_ANSWER_PATTERN)[1] === questionNum
      );
      return {
        id: questionNum,
        question: faqStringResources[qk] || "",
        answer: faqStringResources[answerkey] || "",
      };
    })
    .sort((a, b) => Number(a.id) - Number(b.id));

  faqData.topics = getFaqTopicsPageDataFromResources(faqStringResources);
  console.log(faqData);

  return { faqData };
}
