import { localeApi } from "src/services/api";
import { isLangAbsent, redirectWithLang } from "./utils/loader-utils";

const FAQ_TOPIC_TITLE_PATTERN = "faq.topic.title_";
const FAQ_TOPIC_QUESTION_PATTERN = "faq.topic.question.question_";
const FAQ_TOPIC_ANSWER_PATTERN = "faq.topic.question.answer_";
const FAQ_TOPIC_ICON_PATTERN = "faq.topic.iconName_";

export function getFaqTopicsPageDataFromResources(resources) {
  const topicKeys = [];
  const questionKeys = [];
  const answerKeys = [];
  const iconKeys = [];
  Object.keys(resources).forEach((key) => {
    if (key.startsWith(FAQ_TOPIC_TITLE_PATTERN)) {
      topicKeys.push(key);
    } else if (key.startsWith(FAQ_TOPIC_QUESTION_PATTERN)) {
      questionKeys.push(key);
    } else if (key.startsWith(FAQ_TOPIC_ANSWER_PATTERN)) {
      answerKeys.push(key);
    } else if (key.startsWith(FAQ_TOPIC_ICON_PATTERN)) {
      iconKeys.push(key);
    }
  });

  return topicKeys
    .map((tk) => {
      const topicId = tk.replace(FAQ_TOPIC_TITLE_PATTERN, "");
      const iconKey = iconKeys.find(
        (ik) => ik.replace(FAQ_TOPIC_ICON_PATTERN, "") === topicId
      );
      return {
        id: topicId,
        title: resources[tk] || "",
        icon: resources[iconKey] || "",
        questions: questionKeys
          .filter((qk) => {
            const questionId = qk.replace(FAQ_TOPIC_QUESTION_PATTERN, "");
            const [questionTopicId, questionNum] = questionId.split("-");
            return questionTopicId === topicId && Number.isInteger(Number(questionNum));
          })
          .map((qk) => {
            const questionId = qk.replace(FAQ_TOPIC_QUESTION_PATTERN, "");
            const questionNum = Number(questionId.split("-")[1]);
            const answerkey = answerKeys.find(
              (ak) => ak.replace(FAQ_TOPIC_ANSWER_PATTERN, "") === `${topicId}-${questionNum}`
            );
            return {
              id: questionId,
              question: resources[qk] || "",
              answer: resources[answerkey] || "",
            };
          })
          .sort((a, b) => Number(a.id.split("-")[1]) - Number(b.id.split("-")[1])),
      };
    })
    .filter((topic) => topic.questions.length > 0);
}

export async function loader({ request }) {
  console.log("faq topics page loader");
  if (isLangAbsent(request)) return redirectWithLang(request, window.location.hash);

  const lang = new URL(request.url).searchParams.get("lang");
  const faqStringResources = await localeApi.getFAQResources(lang).catch((err) => {
    console.error(err);
    if (err.response?.status === 404) return { data: {} };
  });
  const faqTopics = getFaqTopicsPageDataFromResources(faqStringResources.data);
  console.log(faqTopics);

  return { faqTopics: faqTopics };
}
