import classes from "./BreadcrumbNavigation.module.css";
import { Fragment } from "react";
import { Link } from "react-router-dom";

function BreadcrumbItem({ name, link, isActive, isDisabled, hideArrow, onClick }) {
  return (
    <Fragment>
      {isDisabled || isActive ? (
        <span
          className={`${classes.step} ${
            isActive ? classes.active : isDisabled ? classes.disabled : ""
          }`}>
          {name}
        </span>
      ) : (
        <Link to={link} replace={true} onClick={onClick} className={classes.step}>
          {name}
        </Link>
      )}
      {!hideArrow && (
        <svg
          className={classes.arrow}
          width="5"
          height="8"
          viewBox="0 0 5 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1 7L4 4L1 1"
            stroke="#929EAC"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </Fragment>
  );
}

export function BreadcrumbNavigation({ id, steps, currentStep, onClick, unlockAllLinks }) {
  return (
    <nav id={id} className={classes.breadcrumbs}>
      {steps.map((step, i) => {
        return (
          <BreadcrumbItem
            key={step.name}
            link={step.link}
            onClick={() => onClick(i)}
            isDisabled={!unlockAllLinks && i > currentStep}
            isActive={i === currentStep}
            name={step.name}
            hideArrow={i === steps.length - 1}
          />
        );
      })}
    </nav>
  );
}
