import { FormInfoElement } from "src/components/UI/FormInfoElement/FormInfoElement";
import classes from "../PaymentOptions.module.css";
import { useLocaleContext } from "src/context/locale-context";

function BankTransfer() {
  const { getStringRes } = useLocaleContext();
  return (
    <div className={classes.transfer}>
      <ul>
        <li>{getStringRes("payment.options.print.invoice")}</li>
        <li>{getStringRes("payment.options.payment.options")}</li>
      </ul>

      <FormInfoElement>
        <p>{getStringRes("prepayment.invoice.info")}</p>
      </FormInfoElement>
    </div>
  );
}

export default BankTransfer;
