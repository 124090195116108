import { useEffect, useState } from "react";
import "./DecrementButton.css";

function DecrementButton({ onClick, disabled }) {
  const [classname, setClassname] = useState("decrement");

  const onButtonClickHandler = () => {
    setClassname("decrement anim");
    onClick();
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setClassname("decrement");
    }, 100);
    return () => clearTimeout(timeoutId);
  }, [classname]);

  return (
    <button onClick={onButtonClickHandler} disabled={disabled} className={classname}>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z"
          stroke="var(--ui-4)"
        />
        <path d="M18.75 15H11.25" stroke="var(--ui-4)" strokeLinecap="round" />
      </svg>
    </button>
  );
}

export default DecrementButton;
