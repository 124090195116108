import { IconChevronLeft } from "@tabler/icons-react";
import { motion } from "framer-motion";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Button from "src/components/UI/Button/Button";
import Card from "src/components/UI/Card/Card";
import PaddedView from "src/components/UI/PaddedView/PaddedView";
import MessageSentSVG from "src/components/UI/SVG/MessageSentSVG";
import { useLocaleContext } from "src/context/locale-context";

function GroupBookingRequestSent() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { getStringRes } = useLocaleContext();

  if (!state || !state.submitted) return <Navigate to=".." />;

  return (
    <PaddedView>
      <motion.section
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.8 }}
        className="post-group-booking">
        <Card className="post-group-booking__card" shadowSm cornerSm>
          <h3>{getStringRes("post.group.booking.title")}</h3>
          <MessageSentSVG />
          <Button
            onClick={() => navigate("..")}
            name={
              <span className="post-group-booking__back">
                <IconChevronLeft />
                {getStringRes("post.group.booking.back")}
              </span>
            }
          />
        </Card>
      </motion.section>
    </PaddedView>
  );
}

export default GroupBookingRequestSent;
