import { countries } from "countries-list";
import Card from "src/components/UI/Card/Card";
import { SelectInput, TextInput } from "src/components/shared/input/FormInput/FormInputSimple";
import { useLocaleContext } from "src/context/locale-context";
import { getCountryName } from "src/utils/utils";

const SHORTEST_INTL_TEL_LENGTH = 7;
const LONGEST_INTL_TEL_LENGTH = 15;

export function BusinessBookingInformation() {
  const { currentLocale, getStringRes } = useLocaleContext();
  const lang = currentLocale.split("_")[0];

  return (
    <section className="group-booking__info">
      <Card className="form-container" shadowSm cornerSm>
        <h3>{getStringRes("business.booking.businessInfo")}</h3>
        <div className="divider"></div>
        <div className="group-info">
          <div className="input-group">
            {getStringRes("business.booking.company.name")}
            <TextInput
              name="companyName"
              containerClass="input-container"
              id="company-name"
              type="text"
              required
            />
          </div>

          <div className="input-group">
            {getStringRes("group.booking.email")}
            <TextInput
              containerClass="input-container"
              name="email"
              id="email"
              type="email"
              required
            />
          </div>

          <div className="group-contact-phone">
            <div className="input-group">
              {getStringRes("group.booking.phone.code")}
              <TextInput
                containerClass="input-container"
                name="phoneCode"
                id="phone-code"
                type="text"
                pattern={`[0-9]+`}
                required
              />
            </div>
            <div className="input-group">
              {getStringRes("group.booking.phone.number")}
              <TextInput
                containerClass="input-container"
                name="phone"
                id="phone"
                type="tel"
                pattern={`[0-9]{${SHORTEST_INTL_TEL_LENGTH},${LONGEST_INTL_TEL_LENGTH}}`}
                required
              />
            </div>
          </div>
          <div></div>

          <div className="input-group">
            {getStringRes("business.booking.company.registration.num")}
            <TextInput
              name="registrationNumber"
              containerClass="input-container"
              id="registration-number"
              type="text"
              required
            />
          </div>

          <div className="input-group">
            {getStringRes("business.booking.company.vat.num")}
            <TextInput
              containerClass="input-container"
              name="vat"
              id="company-vat"
              type="text"
              required
            />
          </div>

          <div className="input-group">
            {getStringRes("business.booking.company.address")}
            <TextInput
              containerClass="input-container"
              name="address"
              id="company-address"
              type="text"
              required
            />
          </div>

          <div className="input-group">
            {getStringRes("business.booking.company.country")}
            <SelectInput
              name="country"
              options={Object.entries(countries)
                .map(([k, v]) => ({
                  key: v.name,
                  value: getCountryName(k, lang, v.name),
                }))
                .sort((a, b) => a.value.localeCompare(b.value))}
              containerClass="input-container"
              id="country"
              required
            />
          </div>
        </div>
      </Card>
    </section>
  );
}
