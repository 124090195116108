import { useState } from "react";
import { FormInfoElement } from "src/components/UI/FormInfoElement/FormInfoElement";
import PaddedView from "src/components/UI/PaddedView/PaddedView";
import TripInfoTag from "src/components/features/dashboard/TripInfoTag/TripInfoTag";
import Footer from "src/components/shared/footer/Footer/Footer";
import { useDeviceContext } from "src/context/device-context";
import { bookingOrderApi } from "src/services/api";
import { BusinessBookingInformation } from "../components/features/group-booking/BusinessBookingInformation";
import { GroupBookingTravelDetails } from "../components/features/group-booking/GroupBookingTravelDetails";
import { SubmissionError } from "./GroupBooking";
import "./styles/GroupBooking.css";
import { useLocaleContext } from "src/context/locale-context";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

const GROUP_INFO_FIELDS = [
  "companyName",
  "address",
  "country",
  "vat",
  "registrationNumber",
  "phoneCode",
  "email",
  "phone",
];
const TRAVEL_INFO_FIELDS = [
  "travelDirection",
  "departureCity",
  "destinationCity",
  "departureDate",
  "returnDate",
  "passengers",
  "price",
  "additionalServices",
  "adults",
  "children",
  "infants",
];

function getParentField(property) {
  if (GROUP_INFO_FIELDS.includes(property)) {
    return "groupInfo";
  } else if (TRAVEL_INFO_FIELDS.includes(property)) {
    return "travelInfo";
  } else {
    return undefined;
  }
}

function BusinessBookingPage() {
  const { isMobile } = useDeviceContext();
  const { getStringRes } = useLocaleContext();
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const showMessageSent = location.pathname.endsWith("/sent");

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setError(null);
    console.log("submit request");
    const formData = new FormData(e.target);
    console.log(formData);
    let finalData = { groupInfo: {}, travelInfo: {} };
    formData.forEach((value, key) => {
      const parentKey = getParentField(key);
      let parentObj = parentKey ? finalData[parentKey] : finalData;
      if (key === "departureDate" || key === "returnDate") {
        parentObj[key] = Intl.DateTimeFormat("lv-LV", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }).format(new Date(value));
      } else if ((key === "adults" || key === "children" || key === "infants") && value) {
        parentObj["passengers"] = { ...parentObj["passengers"], [key]: value };
      } else if (key === "additionalServices") {
        parentObj[key] = (parentObj[key] || []).concat(value);
      } else if (value) {
        parentObj[key] = value;
      }

      if (parentKey) {
        finalData[parentKey] = { ...finalData[parentKey], ...parentObj };
      } else {
        finalData = { ...finalData, ...parentObj };
      }
    });
    finalData.groupInfo.phone = `+${finalData.groupInfo.phoneCode} ${finalData.groupInfo.phone}`;
    delete finalData.groupInfo.phoneCode;
    console.log(finalData);
    try {
      await bookingOrderApi.sendGroupBookingRequest("business", finalData);
      // navigate to post submit page
      navigate("/business/sent", { state: { submitted: true } });
    } catch (error) {
      console.error(error);
      setError(getStringRes("group.booking.submit.error"));
    }
  };

  return (
    <>
      <main id="business-booking-page" className={isMobile ? "mobile" : ""}>
        {error && <SubmissionError message={error} onClose={() => setError(null)} />}
        <div className="header-container">
          <div className="header-graphic"></div>
          <PaddedView>
            <div className="header-content">
              <h1>{getStringRes("business.booking.header.title")}</h1>
              <p>{getStringRes("business.booking.header.description")}</p>
              <p>{getStringRes("business.booking.header.slogan")}</p>
            </div>
          </PaddedView>
        </div>
        <AnimatePresence>{showMessageSent && <Outlet />}</AnimatePresence>
        <AnimatePresence>
          {!showMessageSent && (
            <PaddedView>
              <motion.section
                initial={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="group-booking__header">
                <h2>
                  {getStringRes("business.booking.title")}
                  <TripInfoTag parentClass="group-info-tag">
                    {getStringRes("business.booking.groupSizeInfo")}
                  </TripInfoTag>
                </h2>
                <FormInfoElement type="info">
                  {getStringRes("business.booking.instructions")}
                </FormInfoElement>
              </motion.section>
              <form
                id="group-booking-form"
                onSubmit={onSubmitHandler}
                name="group-booking-form">
                <BusinessBookingInformation />
                <GroupBookingTravelDetails />
              </form>
            </PaddedView>
          )}
        </AnimatePresence>
      </main>
      <Footer />
    </>
  );
}

export default BusinessBookingPage;
