import { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import SaleBanners from "src/components/features/home/SaleBanners/SaleBanners";
import TopDestinations from "src/components/features/home/TopDestinations/TopDestinations";
import SearchForm from "src/components/features/search/SearchForm/SearchForm";
import Footer from "src/components/shared/footer/Footer/Footer";
import { MODAL_TYPE } from "src/constants/modal-definitions";
import { useDeviceContext } from "src/context/device-context";
import { useModalContext } from "src/context/modal-stack-context";
import useSyncChildren from "src/hooks/useSyncChildren";

function HomePage() {
  const { isMobile } = useDeviceContext();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { openModal } = useModalContext();
  const { onLoad, isSynced } = useSyncChildren(["footer"]);

  useEffect(() => {
    if (searchParams.get("login") === "true") {
      const accessedPage = searchParams.get("from") || "/";
      searchParams.delete("login");
      searchParams.delete("from");
      openModal(MODAL_TYPE.loginOptions, {
        onAfterLogin: () => {
          console.log(`${accessedPage}?${searchParams.toString()}`);
          navigate(`${accessedPage}?${searchParams.toString()}`, { replace: true });
        },
        onClose: () => {
          navigate(`/?${searchParams.toString()}`, { replace: true });
        },
      });
    }
  }, [location.pathname, navigate, openModal, searchParams]);

  return (
    <div style={{ display: isSynced ? "contents" : "none" }}>
      <main id="tickets-home-page" className={isMobile ? "mobile" : "desktop"}>
        <SearchForm />
        <div style={{ backgroundColor: "#fff" }}>
          <SaleBanners />
          <TopDestinations />
        </div>
      </main>
      <Footer onLoad={onLoad} />
    </div>
  );
}

export default HomePage;
