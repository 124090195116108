import { useLoaderData, useLocation } from "react-router-dom";
import { DynamicIcon } from "src/components/UI/DynamicIcon/DynamicIcon";
import PaddedView from "src/components/UI/PaddedView/PaddedView";
import { BreadcrumbNavigation } from "src/components/shared/navigation/BreadcrumbNav/BreadcrumbNavigation";
import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";
import { FAQList } from "../components/features/faq/FAQList";
import "./styles/FAQPage.css";
import { useEffect } from "react";

function FAQTopicsPage() {
  const { isMobile } = useDeviceContext();
  const { faqTopics } = useLoaderData();
  const { getStringRes } = useLocaleContext();
  const { hash } = useLocation();
  console.log(hash);

  useEffect(() => {
    if (hash) {
      const timer = setTimeout(() => {
        const el = document.getElementById(hash.slice(1));
        if (el) el.scrollIntoView({ behavior: "smooth" });
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [hash]);

  return (
    <main id="faq-topics-page" className={isMobile ? "mobile" : "desktop"}>
      <PaddedView>
        <BreadcrumbNavigation
          id="faq-topics-breadcrumbs"
          steps={[
            { name: getStringRes("faq.main.page.nav.home"), link: "/" },
            { name: getStringRes("faq.main.page.nav.faq"), link: "/info/faq" },
            { name: getStringRes("faq.main.page.nav.faq.topics"), link: "/info/faq-topics" },
          ]}
          currentStep={2}
          onClick={() => {}}
          unlockAllLinks
        />
        <h1>{getStringRes("faq.main.page.title")}</h1>

        {faqTopics.map((topic) => (
          <section id={topic.id} key={topic.title} className="faq-topic-section">
            <h2 key={topic.id}>
              <a href={`#${topic.id}`}>
                <DynamicIcon iconCode={topic.icon} />
                {topic.title}
                <span className="faq-topic-anchor">#</span>
              </a>
            </h2>

            <FAQList
              listClassName="faq-topic-question-list"
              itemClassName="faq-topic-q-inner"
              questions={topic.questions.map((q) => ({
                ...q,
                isOpen: q.id === hash.slice(1),
              }))}
            />
          </section>
        ))}
      </PaddedView>
    </main>
  );
}

export default FAQTopicsPage;
