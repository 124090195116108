import { motion } from "framer-motion";
import { useState } from "react";
import Card from "src/components/UI/Card/Card";
import { TextInput } from "src/components/shared/input/FormInput/FormInputSimple";
import { useLocaleContext } from "src/context/locale-context";

const SHORTEST_INTL_TEL_LENGTH = 7;
const LONGEST_INTL_TEL_LENGTH = 15;

const GROUP_TYPE_OPTIONS = [
  "educational",
  "sports",
  "tourTravel",
  "artsPerformance",
  "religious",
  "other",
];

const GROUP_TYPE_IMG = {
  educational: require("src/assets/img/illustrations/group_edu.png"),
  sports: require("src/assets/img/illustrations/group_sport.png"),
  tourTravel: require("src/assets/img/illustrations/group_ticket.png"),
  artsPerformance: require("src/assets/img/illustrations/group_arts.png"),
  religious: require("src/assets/img/illustrations/group_religion.png"),
  other: require("src/assets/img/illustrations/group_other.png"),
};

export function GroupBookingInformation() {
  const [groupType, setGroupType] = useState(GROUP_TYPE_OPTIONS[0]);
  const { getStringRes } = useLocaleContext();

  return (
    <section className="group-booking__info">
      <Card className="form-container" shadowSm cornerSm>
        <h3>{getStringRes("group.booking.groupInformation")}</h3>
        <div className="divider"></div>
        <div className="group-info">
          <div className="input-group">
            {getStringRes("group.booking.group.title")}
            <TextInput
              name="groupTitle"
              containerClass="input-container"
              id="group-title"
              type="text"
              required
            />
          </div>

          <div className="input-group">
            {getStringRes("group.booking.contact.name")}
            <TextInput
              name="contactPersonName"
              containerClass="input-container"
              id="contact-name"
              type="text"
              required
            />
          </div>

          <div className="input-group">
            {getStringRes("group.booking.email")}
            <TextInput
              containerClass="input-container"
              name="email"
              id="email"
              type="email"
              required
            />
          </div>

          <div className="group-contact-phone">
            <div className="input-group">
              {getStringRes("group.booking.phone.code")}
              <TextInput
                containerClass="input-container"
                name="phoneCode"
                id="phone-code"
                type="text"
                pattern={`[0-9]+`}
                required
              />
            </div>
            <div className="input-group">
              {getStringRes("group.booking.phone.number")}
              <TextInput
                containerClass="input-container"
                name="phone"
                id="phone"
                type="tel"
                pattern={`[0-9]{${SHORTEST_INTL_TEL_LENGTH},${LONGEST_INTL_TEL_LENGTH}}`}
                required
              />
            </div>
          </div>
        </div>
        <h4>{getStringRes("group.booking.groupTypePrompt")}</h4>
        <ul className="group-type">
          {GROUP_TYPE_OPTIONS.map((category, index) => (
            <li className="group-type-item" key={index}>
              <motion.button
                whileTap={{ scale: 0.95 }}
                className={groupType === category ? "active" : ""}
                type="button"
                onClick={() => setGroupType(category)}>
                <input
                  hidden
                  defaultChecked={groupType === category}
                  type="radio"
                  name="groupType"
                  value={getStringRes(`group.booking.groupType.${category}`)}
                  id={`group-type-${category}`}
                />
                <div className="group-graphic">
                  <img alt={category} src={GROUP_TYPE_IMG[category]} />
                </div>
                <div className="group-item-info">
                  <div className="group-item-name">
                    {getStringRes(`group.booking.groupType.${category}`)}
                  </div>
                  <div className="group-item-detail">
                    {getStringRes(`group.booking.groupType.${category}.detail`)}
                  </div>
                </div>
              </motion.button>
            </li>
          ))}
        </ul>
      </Card>
    </section>
  );
}
