import { AnimatePresence, motion } from "framer-motion";
import { useLocaleContext } from "src/context/locale-context";
import { PAYMENT_TYPE } from "../PaymentOptions";
import classes from "../PaymentOptions.module.css";
import { useDeviceContext } from "src/context/device-context";

export function SelectedType({ type, content, note, onChange }) {
  const { getStringRes } = useLocaleContext();
  const { isMobile } = useDeviceContext();

  return (
    <motion.div className={`${classes.selectedType} ${isMobile ? classes.mobile : classes.desktop}`}>
      <div style={{ marginBottom: content ? "1.75rem" : "0" }} className={classes.header}>
        <div>
          <span className={classes.icon}>{PAYMENT_TYPE[type].icon}</span>
          <span className={classes.name}>
            <span>{getStringRes(PAYMENT_TYPE[type].strKey)}</span>
            {note && <span className={classes.paymentTypeNote}>{getStringRes(note)}</span>}
          </span>
        </div>
        <button onClick={onChange} className={classes.changeBtn}>
          {getStringRes("booking.checkout.payment.option.change")}
        </button>
      </div>
      <AnimatePresence>{content}</AnimatePresence>
    </motion.div>
  );
}
