import { json } from "react-router-dom";
import { BREAKDOWN_ITEM_STR_ENGLISH } from "src/constants/order-str-map";
import { bookingOrderApi, paymentApi } from "src/services/api";
import store from "src/store";
import { resetAfterBooking } from "src/store/actions";
import { unpackOrderToken } from "src/utils/order-utils";
import { clearBookingSessionData } from "src/utils/storage-utils";
import { customLog } from "src/utils/utils";
import { isLangAbsent, redirectWithLang } from "./utils/loader-utils";

export async function loader({ request, params }) {
  if (isLangAbsent(request)) return redirectWithLang(request);
  customLog(request);

  clearBookingSessionData();
  store.dispatch(resetAfterBooking());

  const ONE_DAY_IN_SECS = 60 * 60 * 24;

  const url = new URL(request.url);
  const searchParams = url.searchParams;
  const bookingNumber = searchParams.get("booking-number");
  const ulrToken = searchParams.get("order-token");
  if (!ulrToken) {
    throw json(null, { status: 404 });
  }

  const response = await paymentApi
    .checkPaymentOrderToken(ulrToken)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      throw json({ data: err });
    });

  const { token, isValid } = response.data;
  if (!isValid) {
    throw json({ isValid }, { status: 404 });
  }

  const bookingID = params.orderId;
  const decodedOrderData = unpackOrderToken(token);
  if (decodedOrderData.merchant_reference !== bookingID) {
    throw json(null, { status: 404 });
  }

  if (Date.now() / 1000 - decodedOrderData.iat > ONE_DAY_IN_SECS) {
    throw json(null, { status: 410 });
  }

  let order = {};
  await bookingOrderApi
    .getOrderItems(bookingID)
    .then((res) => {
      console.log(res.data);
      const commitData = res.data.commit;
      if (commitData) {
        order.transaction_id = res.data.booking.booking_number;
        order.value = commitData.payload.cart.total;
        order.currency = "EUR";
        order.items = commitData.payload.cart.items
          .filter((item) => item.isActiveOrderItem)
          .map((item) => ({
            item_name: BREAKDOWN_ITEM_STR_ENGLISH[item.key],
            item_id: item.key,
            quantity: item.count || 1,
            price: item.price,
          }))
          .reduce((arr, item) => {
            const existing = arr.find((v) => v.item_id === item.item_id);
            if (existing) {
              existing.quantity += item.quantity;
            } else {
              arr.push(item);
            }
            return arr;
          }, []);
      }
    })
    .catch((err) => console.log(err));

  return {
    decodedOrderData,
    bookingID,
    bookingNumber,
    order,
  };
}
