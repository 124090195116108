import { useEffect, useState } from "react";
import { useMatch, useRouteLoaderData, useSearchParams } from "react-router-dom";
import { BOOKING_STEP } from "src/constants";
import { useBookingFlow } from "src/context/booking-flow-context";
import { useLocaleContext } from "src/context/locale-context";
import { customLog } from "src/utils/utils";
import classes from "./BookingStepsIndicator.module.css";
import { BreadcrumbNavigation } from "src/components/shared/navigation/BreadcrumbNav/BreadcrumbNavigation";

export const BOOKING_STEP_STR_MAP = [
  { pageId: BOOKING_STEP.searchResults.id, stepName: "booking.indicator.step.results" },
  { pageId: BOOKING_STEP.fareTypeSelection.id, stepName: "booking.indicator.step.fares" },
  {
    pageId: BOOKING_STEP.passengerDetailsForm.id,
    stepName: "booking.indicator.step.passengers",
  },
  {
    pageId: BOOKING_STEP.guaranteeSelection.id,
    stepName: "booking.indicator.step.guarantee",
  },
  {
    pageId: BOOKING_STEP.insuranceSelection.id,
    stepName: "booking.indicator.step.insurance",
  },
  {
    pageId: BOOKING_STEP.extraServicesSelection.id,
    stepName: "booking.indicator.step.additional",
  },
  { pageId: BOOKING_STEP.seatSelection.id, stepName: "booking.indicator.step.seats" },
  {
    pageId: BOOKING_STEP.supportSelection.id,
    stepName: "booking.indicator.step.support",
  },
  { pageId: BOOKING_STEP.checkoutPage.id, stepName: "booking.indicator.step.payment" },
];

function BookingStepsIndicator() {
  const { getStringRes } = useLocaleContext();
  const { searchQuery, getAllEnabledPages } = useBookingFlow();
  const [pagesInOrder, setPagesInOrder] = useState([]);
  const [currentStep, setStep] = useState(0);
  const { travelPackage } = useRouteLoaderData("bookingRouteController");

  useEffect(() => {
    setPagesInOrder([BOOKING_STEP.searchResults.id].concat(getAllEnabledPages()));
  }, [getAllEnabledPages]);

  const [searchParams] = useSearchParams();
  const match = useMatch("/booking/:bookingStep");
  const { bookingStep } = match.params;

  const STEP_LINKS = pagesInOrder.map((pageId) => {
    if (pageId === BOOKING_STEP.searchResults.id) {
      return `/${BOOKING_STEP.searchResults.parentPath}/${BOOKING_STEP.searchResults.path}?${searchQuery}`;
    } else {
      return BOOKING_STEP[pageId].path + (searchParams ? "?" + searchParams.toString() : "");
    }
  });

  useEffect(() => {
    customLog(bookingStep);
    const bookingValue = Object.values(BOOKING_STEP).find((v) => v.path === bookingStep);
    if (bookingValue) {
      const currPathIndex = pagesInOrder.indexOf(bookingValue.id);
      setStep(currPathIndex > -1 ? currPathIndex : 0);
    } else {
      setStep(0);
    }
  }, [bookingStep, pagesInOrder]);

  const onClick = (i) => {
    setStep(i);
  };

  return (
    <div className={classes.steps}>
      <p>{`${getStringRes("booking.indicator.orderID")} ${travelPackage.booking_number}`}</p>
      <BreadcrumbNavigation
        steps={pagesInOrder.map((pageId, i) => {
          const mapObject = BOOKING_STEP_STR_MAP.find((item) => item.pageId === pageId);
          return { name: getStringRes(mapObject.stepName), link: STEP_LINKS[i] };
        })}
        currentStep={currentStep}
        onClick={onClick}
      />
    </div>
  );
}

export default BookingStepsIndicator;
