export const getServiceFee = (item) => {
  // FIXME: access service fee from the correct field
  // return item.price.EUR.agent_prices.extra_markup_fee_per_person;
  return 0;
};

export const hasServiceFee = (item) => {
  // return !!item.price.EUR.agent_prices.extra_markup_fee_per_person;
  return false; //FIXME: evaluate if has service fee
};

export const getPricePerPerson = (item) => {
  return item.price.EUR.price_per_person;
};

export const getOldPricePerPerson = (item) => {
  return item.price.EUR.old_price_per_person ?? 0;
};

export const getTotalPricePerPerson = (item) => {
  return item.price.EUR.total_price_per_person;
};

export const getPackageFaresList = (item) => {
  return item.price.EUR.passengers_amounts_details;
};

export const getFirstFlightChain = (item) => {
  return item.segments.filter((fl, i) => item.segments_direction[0].includes(i));
};

export const getAllFlightChains = (item) => {
  const finalArr = [];
  for (let i = 0; i < item.segments_direction.length; i++) {
    const segmentIndex = item.segments_direction[i];
    let currentChainFlights = [];
    for (let z = 0; z < segmentIndex.length; z++) {
      currentChainFlights.push(item.segments[segmentIndex[z]]);
    }
    finalArr.push(currentChainFlights);
  }

  return finalArr;
};

export const getLastFlightChain = (item) => {
  return item.segments.filter((fl, i) =>
    item.segments_direction[item.segments_direction.length - 1].includes(i)
  );
};

export const getAllFlights = (item) => {
  return item.segments;
};

export const getFlightDate = (flight) => {
  return flight.dep.date;
};

export const isDepartureArrivalDifferent = (firstChain, lastChain) => {
  return firstChain[firstChain.length - 1].arr.airport.code !== lastChain[0].dep.airport.code;
};

export const getCarrierIcon = (flight) => {
  return (
    `https://${window.SERVER_DATA.REACT_APP_BUCKET_NAME}.` +
    `${window.SERVER_DATA.REACT_APP_BUCKET_PATH}/cache/${flight.carrier.code}.png`
  );
};

export const getMainCarrierAirlineIcon = (flight) => {
  let imgVar = flight.carrier.code;
  if (flight.carrier.code !== flight.supplier.code) {
    imgVar = flight.supplier.code;
  }
  return (
    `https://${window.SERVER_DATA.REACT_APP_BUCKET_NAME}.` +
    `${window.SERVER_DATA.REACT_APP_BUCKET_PATH}/cache/${imgVar}.png`
  );
};

const getSeatsNumWarningRange = (passengerNum) => {
  switch (passengerNum) {
    case 9:
      return [passengerNum, passengerNum];
    case 8:
      return [passengerNum, passengerNum + 1];
    default:
      return [passengerNum, passengerNum + 2];
  }
};

export const showSearchSeatsLeft = (seatNum, passengerNum) => {
  const seatsWarningRange = getSeatsNumWarningRange(passengerNum);
  return seatNum < 10 && seatNum >= seatsWarningRange[0] && seatNum <= seatsWarningRange[1];
};

export const showBookingSeatsLeft = (seatNum, passengerNum) => {
  const seatsWarningRange = getSeatsNumWarningRange(passengerNum);
  return seatNum < 10 && seatNum <= seatsWarningRange[1];
};

export const isRoundTrip = (item) => {
  const isTwoChains = item.segments_direction?.length === 2;
  if (!isTwoChains) return false;

  const from = item.segments[0].dep.airport.code;
  const returnTo = item.segments.at(-1).arr.airport.code;
  
  return from === returnTo;
};