import { Link, NavLink, useLocation } from "react-router-dom";

const COPIED_PARAMS = ["lang", "login", "from"];

function getFilteredSearch(search) {
  return (
    "?" +
    search
      .slice(1)
      .split("&")
      .filter((param) => COPIED_PARAMS.includes(param.split("=")[0]))
      .join("&")
  );
}

export function NavLinkWithQuery({ to, children, ...props }) {
  const { search } = useLocation();

  return (
    <NavLink to={to + getFilteredSearch(search)} {...props}>
      {children}
    </NavLink>
  );
}

export function LinkWithQuery({ to, children, ...props }) {
  const { search } = useLocation();

  return (
    <Link to={to + getFilteredSearch(search)} {...props}>
      {children}
    </Link>
  );
}
