import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "src/context/auth-context";

const withAuthControl = (Component) => (props) => {
  const { isAuthenticated, loaded } = useAuth();
  const { pathname } = useLocation();

  if (loaded) {
    if (!isAuthenticated) {
      return <Navigate to={"/?login=true&from=" + pathname} replace />;
    }

    return <Component {...props} />;
  }
};

withAuthControl.displayName = "withAuthControl";
export default withAuthControl;
