import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import TagManager from "react-gtm-module";

const tagManagerArgs = { gtmId: window.SERVER_DATA.REACT_APP_GTM_ID };
TagManager.initialize(tagManagerArgs);

// ATTACH CUSTOM TENANT STYLES
const cssScript = document.createElement("link");
cssScript.rel = "stylesheet";
cssScript.type = "text/css";
cssScript.href =
  `https://${window.SERVER_DATA.REACT_APP_BUCKET_NAME}.` +
  `${window.SERVER_DATA.REACT_APP_BUCKET_PATH}/static/` +
  `${window.SERVER_DATA.REACT_APP_TENANT_NAME}/en/assets/styles.css`;
document.head.appendChild(cssScript);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals(console.log);
