import classes from "../PaymentOptions.module.css";
import { useLocaleContext } from "src/context/locale-context";
import { PAYMENT_TYPE } from "../PaymentOptions";
import { useDeviceContext } from "src/context/device-context";

export function PaymentType({ id, note }) {
  const { isMobile } = useDeviceContext();
  const { getStringRes } = useLocaleContext();
  const paymentName = getStringRes(PAYMENT_TYPE[id].strKey);
  const paymentIcon = PAYMENT_TYPE[id].icon;

  return (
    <div className={`${classes.details} ${isMobile ? classes.mobile : classes.desktop}`}>
      <div className={classes.icon}>{paymentIcon}</div>
      <div className={classes.name}>
        <span>{paymentName}</span>
        {note && <span className={classes.paymentTypeNote}>{getStringRes(note)}</span>}
      </div>
    </div>
  );
}
