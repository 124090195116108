import { forwardRef } from "react";
import { useDispatch } from "react-redux";
import RadioButton from "src/components/UI/RadioButton/RadioButton";
import { bookingActions } from "src/store/booking";
import classes from "../PaymentOptions.module.css";
import { PaymentType } from "./PaymentType";
import { PAYMENT_TYPE } from "../PaymentOptions";

export const UnselectedPaymentOptions = forwardRef(function UnselectedPaymentOptions(
  props,
  ref
) {
  const dispatch = useDispatch();

  const onSelectPaymentTypeHandler = (type) => {
    dispatch(bookingActions.updatePaymentType(type));
    props.setIsExpanded(true);
    props.setFormState((fState) => {
      return { ...fState, paymentMethod: { isValid: !!type, hasBeenTouched: true } };
    });
  };

  return (
    <form
      ref={(r) => {
        if (ref) ref["paymentMethod"] = r;
      }}
      name="payment-type-selection-form"
      className={`${classes.paymentTypes} ${
        !props.formState.paymentMethod.isValid && props.formState.paymentMethod.hasBeenTouched
          ? classes.warn
          : ""
      }`}>
      <RadioButton
        className={classes.paymentType}
        id="payment-type-bank"
        name="payment-type"
        onChange={() => onSelectPaymentTypeHandler(PAYMENT_TYPE.paymentInitiation.key)}
        checked={props.selectedType === PAYMENT_TYPE.paymentInitiation.key}>
        <PaymentType selected={props.selectedType} id={PAYMENT_TYPE.paymentInitiation.key} />
      </RadioButton>
      <RadioButton
        className={classes.paymentType}
        id="payment-type-transfer"
        name="payment-type"
        onChange={() => onSelectPaymentTypeHandler(PAYMENT_TYPE.bankTransfer.key)}
        checked={props.selectedType === PAYMENT_TYPE.bankTransfer.key}>
        <PaymentType selected={props.selectedType} id={PAYMENT_TYPE.bankTransfer.key} />
      </RadioButton>
      <RadioButton
        className={classes.paymentType}
        id="payment-type-card"
        name="payment-type"
        onChange={() => onSelectPaymentTypeHandler(PAYMENT_TYPE.cardPayments.key)}
        checked={props.selectedType === PAYMENT_TYPE.cardPayments.key}>
        <PaymentType
          note="booking.order.breakdown.cardPaymentFee"
          selected={props.selectedType}
          id={PAYMENT_TYPE.cardPayments.key}
        />
      </RadioButton>
      <RadioButton
        className={classes.paymentType}
        id="payment-type-offline"
        name="payment-type"
        onChange={() => onSelectPaymentTypeHandler(PAYMENT_TYPE.offlinePayment.key)}
        checked={props.selectedType === PAYMENT_TYPE.offlinePayment.key}>
        <PaymentType selected={props.selectedType} id={PAYMENT_TYPE.offlinePayment.key} />
      </RadioButton>
    </form>
  );
});
