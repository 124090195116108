import { FormInfoElement } from "src/components/UI/FormInfoElement/FormInfoElement";
import PaddedView from "src/components/UI/PaddedView/PaddedView";
import TripInfoTag from "src/components/features/dashboard/TripInfoTag/TripInfoTag";
import { GroupBookingInformation } from "../components/features/group-booking/GroupBookingInformation";
import { GroupBookingTravelDetails } from "../components/features/group-booking/GroupBookingTravelDetails";
import "./styles/GroupBooking.css";
import Footer from "src/components/shared/footer/Footer/Footer";
import { useDeviceContext } from "src/context/device-context";
import { bookingOrderApi } from "src/services/api";
import { useState } from "react";
import Button from "src/components/UI/Button/Button";
import Card from "src/components/UI/Card/Card";
import { useLocaleContext } from "src/context/locale-context";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const GROUP_INFO_FIELDS = [
  "groupTitle",
  "contactPersonName",
  "phoneCode",
  "email",
  "phone",
  "groupType",
];
const TRAVEL_INFO_FIELDS = [
  "travelDirection",
  "departureCity",
  "destinationCity",
  "departureDate",
  "returnDate",
  "passengers",
  "price",
  "additionalServices",
  "adults",
  "children",
  "infants",
];

export function SubmissionError({ message, onClose }) {
  const { getStringRes } = useLocaleContext();
  return (
    <>
      <div className="submision-error-modal-background"></div>
      <div className="submision-error-modal">
        <Card cornerSm shadowLg>
          <div className="submission-error">{message}</div>
          <Button secondary name={getStringRes("modal.button.close")} onClick={onClose} />
        </Card>
      </div>
    </>
  );
}

function getParentField(property) {
  if (GROUP_INFO_FIELDS.includes(property)) {
    return "groupInfo";
  } else if (TRAVEL_INFO_FIELDS.includes(property)) {
    return "travelInfo";
  } else {
    return undefined;
  }
}

function GroupBookingPage() {
  const { isMobile } = useDeviceContext();
  const { getStringRes } = useLocaleContext();
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const showMessageSent = location.pathname.endsWith("/sent");

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setError(null);
    console.log("submit request");
    const formData = new FormData(e.target);
    console.log(formData);
    let finalData = { groupInfo: {}, travelInfo: {} };
    formData.forEach((value, key) => {
      const parentKey = getParentField(key);
      let parentObj = parentKey ? finalData[parentKey] : finalData;
      if (key === "departureDate" || key === "returnDate") {
        parentObj[key] = Intl.DateTimeFormat("lv-LV", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }).format(new Date(value));
      } else if ((key === "adults" || key === "children" || key === "infants") && value) {
        parentObj["passengers"] = { ...parentObj["passengers"], [key]: value };
      } else if (key === "additionalServices") {
        parentObj[key] = (parentObj[key] || []).concat(value);
      } else if (value) {
        parentObj[key] = value;
      }
      // console.log(parentObj);
      if (parentKey) {
        finalData[parentKey] = { ...finalData[parentKey], ...parentObj };
      } else {
        finalData = { ...finalData, ...parentObj };
      }
    });
    finalData.groupInfo.phone = `+${finalData.groupInfo.phoneCode} ${finalData.groupInfo.phone}`;
    delete finalData.groupInfo.phoneCode;
    console.log(finalData);
    try {
      await bookingOrderApi.sendGroupBookingRequest("group", finalData);
      // navigate to post submit page
      navigate("/groups/sent", { state: { submitted: true } });
    } catch (error) {
      console.error(error);
      setError(getStringRes("group.booking.submit.error"));
    }
  };

  return (
    <>
      <main id="group-booking-page" className={isMobile ? "mobile" : ""}>
        <div className="header-container">
          <div className="header-graphic"></div>
          <PaddedView>
            <div className="header-content">
              <h1>{getStringRes("group.booking.header.title")}</h1>
              <p>{getStringRes("group.booking.header.description")}</p>
              <p>{getStringRes("group.booking.header.slogan")}</p>
            </div>
          </PaddedView>
        </div>
        {showMessageSent && <Outlet />}
        {!showMessageSent && (
          <PaddedView>
            {error && <SubmissionError message={error} onClose={() => setError(null)} />}
            <section className="group-booking__header">
              <h2>
                {getStringRes("group.booking.title")}
                <TripInfoTag parentClass="group-info-tag">
                  {getStringRes("group.booking.groupSizeInfo")}
                </TripInfoTag>
              </h2>
              <FormInfoElement type="info">
                {getStringRes("group.booking.instructions")}
              </FormInfoElement>
            </section>
            <form id="group-booking-form" onSubmit={onSubmitHandler} name="group-booking-form">
              <GroupBookingInformation />
              <GroupBookingTravelDetails />
            </form>
          </PaddedView>
        )}
      </main>
      <Footer />
    </>
  );
}

export default GroupBookingPage;
