import { useEffect, useState } from "react";

const loadIcon = async (iconCode) => {
  try {
    return (await import("@tabler/icons-react"))[iconCode];
  } catch (error) {
    console.error(`Error loading icon ${iconCode}:`, error);
    return null;
  }
};

export const DynamicIcon = ({ iconCode, size = 16 }) => {
  const [IconComponent, setIconComponent] = useState(null);

  useEffect(() => {
    const fetchIcon = async () => {
      const icon = await loadIcon(iconCode);
      setIconComponent(icon); // Set the icon component
    };

    fetchIcon();
  }, [iconCode]);

  return IconComponent && <IconComponent size={size} />;
};
