export function getPluralizedCaseCode(itemNum) {
  const numStr = String(itemNum);
  if (itemNum === 0) return 0;

  const lastDigit = Number(numStr[numStr.length - 1]);
  if (lastDigit === 1) return 1;
  if (lastDigit > 0 && lastDigit < 5) {
    return 2;
  }
  return 5;
}

export function getCalendarDaysLeft(trip) {
  const FULL_DAY_IN_MILLIS = 1000 * 60 * 60 * 24;
  const today = new Date().setHours(0, 0, 0, 0);
  const startDate = new Date(trip.segments[0].dep.timestamp * 1000).setHours(0, 0, 0, 0);
  const diffTime = startDate - today;
  const diffDays = Math.ceil(diffTime / FULL_DAY_IN_MILLIS);

  return diffDays;
}

export const STRING_VARS = {
  daysNum: "##DAYS_NUM##",
  tripNum: "##TRIP_NUM##",
  stopNum: "##STOPS_NUM##",
  paxNum: "##PAX_NUM##",
  docsMissing: "##DOCS_MISSING##",
};

export function getDaysLeftText(trip, isMobile, getStringRes) {
  const daysLeft = getCalendarDaysLeft(trip);
  if (trip.past) return getStringRes("dashboard.trips.traveled");
  if (daysLeft === 0) return getStringRes("dashboard.trips.daysToGo_0");

  let baseDaysLeftText = getStringRes(
    `dashboard.trips.daysToGo_${getPluralizedCaseCode(daysLeft)}`
  ).replace(STRING_VARS.daysNum, daysLeft);

  if (isMobile) return baseDaysLeftText;

  return `${getStringRes("dashboard.trips.timeAfter")} ${baseDaysLeftText}`;
}
