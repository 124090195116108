import { isAnyOf } from "@reduxjs/toolkit";
import { PASSENGER } from "src/constants";
import { BOOKING } from "src/constants/booking";
import { CATALOGUE_IDS } from "src/constants/services";
import { removePassenger, updatePassenger } from "src/store/actions";
import { selectCatalogueItem } from "src/store/catalogue";
import { BAGGAGE } from "../baggage-utils";
import { customLog } from "../utils";
import { bookingActions } from "src/store/booking";
import { AGE_GROUP_MAX, AGE_GROUP_MIN } from "src/constants/validation";
import { isValidAgeOnFlightDate } from "../date-utils";
import { getFlightDate } from "../results-utils";

function passengersListenersEffect(action, listenerApi, send) {
  customLog(action);
  const bookingActionsQueue = [];
  const bookingAction = {
    command: "",
    payload: "",
  };

  const flightDate = getFlightDate(listenerApi.getState().booking.travelPackage.segments[0]);
  const _oldBag = listenerApi
    .getOriginalState()
    .order.baggage.find((b) => b.passengerId === action.payload.passengerId);
  const _old = listenerApi
    .getOriginalState()
    .booking.passengers.find((p) => p.id === action.payload.passengerId);
  const _new = listenerApi
    .getState()
    .booking.passengers.find((p) => p.id === action.payload.passengerId);

  if (
    action.type === "passenger/remove" ||
    (action.type === "passenger/update" &&
      _new.ageGroup === PASSENGER.infant &&
      _new.ageGroup !== _old.ageGroup)
  ) {
    if (_old.passenger_id && _oldBag && Object.values(_oldBag).some((v) => !!v)) {
      if (_oldBag.cabin.serviceId) {
        bookingActionsQueue.push({
          command: BOOKING.REMOVE_SERVICE_ANCILLARY,
          payload: {
            type: "baggage",
            id:
              _oldBag.cabin.serviceId === CATALOGUE_IDS.pi_baggage
                ? BAGGAGE.personal
                : _oldBag.cabin.serviceId === CATALOGUE_IDS.cob_baggage_upgrade
                ? BAGGAGE.cabinBundleUpgrade
                : BAGGAGE.cabinBundle,
            passenger_id: _old.passenger_id,
            passenger_client_id: _old.id,
          },
        });
      }
      if (_oldBag.checked.serviceId) {
        bookingActionsQueue.push({
          command: BOOKING.REMOVE_SERVICE_ANCILLARY,
          payload: {
            type: "baggage",
            id: BAGGAGE.checked,
            passenger_id: _old.passenger_id,
            passenger_client_id: _old.id,
          },
        });
      }
      if (_oldBag.protection.serviceId) {
        const serviceDetails = selectCatalogueItem(
          listenerApi.getState().catalogue.catalogue,
          CATALOGUE_IDS.BAG_PROTECTION
        );
        bookingActionsQueue.push({
          command: BOOKING.REMOVE_SERVICE_ADDITIONAL,
          payload: {
            id: serviceDetails.data.id,
            passenger_id: _old.passenger_id,
            passenger_client_id: _old.id,
          },
        });
      }
    }

    if (action.type === "passenger/remove" && _old.passenger_id) {
      bookingAction.payload = {
        passenger_id: _old.passenger_id,
        passenger_client_id: _old.id,
      };
      bookingAction.command = BOOKING.REMOVE_PASSENGER;
      bookingActionsQueue.push(bookingAction);
    } else if (action.type === "passenger/update" && _new.passenger_id) {
      bookingAction.payload = { ...bookingAction.payload, ..._new };
      delete bookingAction.payload.skipCheckedBaggage;
      bookingAction.command = BOOKING.UPDATE_PASSENGER;
      bookingActionsQueue.push(bookingAction);
    }
  } else {
    if (_new && _new.name && _new.surname) {
      bookingAction.payload = { ...bookingAction.payload, ..._new };
      delete bookingAction.payload.skipCheckedBaggage;

      if (_new.passenger_id) {
        //TODO: and not loading/waiting confirmation
        bookingAction.command = BOOKING.UPDATE_PASSENGER;
        bookingAction.payload.passenger_id = _new.passenger_id;
        bookingActionsQueue.push(bookingAction);
      } else {
        //TODO: if not loading/waiting confirmation
        //TODO: mark this passenger as loading/waiting confirmation
        bookingAction.command = BOOKING.ADD_PASSENGER;
        bookingActionsQueue.push(bookingAction);
      }
    } else {
      customLog(
        "Passenger id: " +
          action.payload.passengerId +
          " not found OR passenger name/surname missing"
      );
    }
  }

  const mainPassenger = listenerApi.getState().booking.passengers.find((p) => p.main);
  if (
    _new &&
    _new.id === mainPassenger.id &&
    _new.ageGroup === PASSENGER.adult &&
    _new.name &&
    _new.surname &&
    _new.nationality &&
    _new.gender &&
    _new.dob &&
    isValidAgeOnFlightDate(
      _new.dob,
      AGE_GROUP_MAX["adult"],
      AGE_GROUP_MIN["adult"],
      flightDate
    )
  ) {
    const mainPaxAction = {
      command: BOOKING.MAIN_PASSENGER_COMPLETE,
      payload: { ..._new, passenger_client_id: _new.id },
    };
    delete mainPaxAction.payload.skipCheckedBaggage;
    bookingActionsQueue.push(mainPaxAction);
  }

  bookingActionsQueue.forEach((nextAction) => send(nextAction));

  const paxsCommited = listenerApi.getState().booking.passengerState.committedPaxs;
  if (paxsCommited) {
    listenerApi.dispatch(bookingActions.setPassengerState({ paxsChangedSinceCommit: true }));
  }
}

function passengersIdListenersEffect(action, listenerApi, send) {
  customLog(action);

  const _new = listenerApi
    .getState()
    .booking.passengers.find((p) => p.id === action.payload.id);

  if (_new) {
    const bookingAction = {
      command: BOOKING.UPDATE_PASSENGER,
      payload: { ..._new, passenger_id: _new.passenger_id },
    };
    delete bookingAction.payload.skipCheckedBaggage;

    send(bookingAction);
  }

  const paxsCommited = listenerApi.getState().booking.passengerState.committedPaxs;
  if (paxsCommited) {
    listenerApi.dispatch(bookingActions.setPassengerState({ paxsChangedSinceCommit: true }));
  }
}

export function getPassengersListenerOpts(send) {
  const passengersListenerOpts = [
    {
      matcher: isAnyOf(removePassenger, updatePassenger),
      effect: (action, listenerApi) => passengersListenersEffect(action, listenerApi, send),
    },
    {
      type: "booking/setPassengerServerIdentifier",
      effect: (action, listenerApi) => passengersIdListenersEffect(action, listenerApi, send),
    },
  ];

  return passengersListenerOpts;
}
