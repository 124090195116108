import { motion } from "framer-motion";
import { Markup } from "interweave";
import { useCallback, useEffect, useRef, useState } from "react";
import { CollapseArrow, ExpandArrow } from "src/components/UI/Arrow/Arrow";
import "src/pages/styles/FAQPage.css";

export function CollapsibleAnswer({
  question,
  answer,
  isOpenOnInit = true,
  containerClass = "",
}) {
  const itemRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [TITLE_HEIGHT, updateTitleHeight] = useState(0);
  const [height, setHeight] = useState(TITLE_HEIGHT);

  const variants = {
    open: { height: height, transition: { duration: 0.4 } },
    closed: { height: TITLE_HEIGHT, transition: { duration: 0.4 } },
  };

  const onToggleHandler = useCallback(
    (isCurrenylyOpen) => {
      const willBeExpanded = !isCurrenylyOpen;
      const height = willBeExpanded
        ? `${itemRef.current.scrollHeight / 16 + 0.125}rem`
        : TITLE_HEIGHT;
      setOpen((prev) => !prev);
      setHeight(() => height);
    },
    [TITLE_HEIGHT]
  );

  const resizeTitleBtnHeight = useCallback(() => {
    const titleBtnHeight =
      itemRef.current.getElementsByClassName("item-collapse-title")[0].offsetHeight;
    updateTitleHeight(`${titleBtnHeight / 16 + 0.125}rem`);
  }, []);

  useEffect(() => {
    function handleResize() {
      if (itemRef.current) resizeTitleBtnHeight();
    }

    const observer = new MutationObserver(handleResize);
    if (itemRef.current) {
      observer.observe(itemRef.current, { attributes: true, subtree: true });
    }

    handleResize(); // Initial calculation
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      observer.disconnect();
    };
  }, [resizeTitleBtnHeight]);

  useEffect(() => {
    if (!isOpenOnInit) return;
    if (!itemRef.current) return;
    const height = `${itemRef.current.scrollHeight / 16 + 0.125}rem`;
    setOpen((prev) => !prev);
    setHeight(() => height);
  }, [isOpenOnInit]);

  return (
    <motion.div
      ref={itemRef}
      initial={variants.closed}
      animate={{ ...variants[open ? "open" : "closed"] }}
      className={containerClass + " item-collapse " + (open ? "open" : "closed")}>
      <button onClick={() => onToggleHandler(open)} className="item-collapse-title">
        <span>{question}</span>
        {open ? <CollapseArrow /> : <ExpandArrow />}
      </button>
      <motion.p
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.4 }}
        className="item-collapse-content">
        <Markup content={answer} />
      </motion.p>
    </motion.div>
  );
}
