export const guestMenuItems = [
  // {
  //   name: "main.nav.discover",
  //   items: [
  //     { name: "main.nav.discover.newsletter", to: "/newsletter" },
  //     { name: "main.nav.discover.stories", to: "/stories" },
  //   ],
  // },
  {
    name: "main.nav.terms",
    items: [
      { name: "main.nav.terms.tos", to: "/info/terms-of-service" },
      { name: "main.nav.terms.privacy", to: "/info/privacy-policy" },
      { name: "main.nav.terms.refund", to: "/info/cancellation-policy" },
      { name: "main.nav.terms.guarantee", to: "/info/guarantee-rules" },
    ],
  },
  {
    name: "main.nav.company",
    items: [{ name: "main.nav.company.about", to: "/about-us" }],
  },
  {
    name: "main.nav.help",
    items: [
      { name: "main.nav.help.faq", to: "/info/faq" },
      // { name: "main.nav.help.support", to: "/support" },
    ],
  },
  {
    name: "main.nav.special.booking",
    items: [
      { name: "main.nav.business", to: "/business" },
      { name: "main.nav.groups", to: "/groups" },
    ],
  },
];
