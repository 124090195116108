import { IconPhotoFilled } from "@tabler/icons-react";
import { motion } from "framer-motion";
import { useCallback, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Button from "src/components/UI/Button/Button";
import PaddedView from "src/components/UI/PaddedView/PaddedView";
import { useDeviceContext } from "src/context/device-context";
import { useLocaleContext } from "src/context/locale-context";
import { destinationsApi } from "src/services/api";
import classes from "./TopDestinations.module.css";

function Destination({ deal }) {
  const [imgErr, setImgErr] = useState(false);
  const { getStringRes, currentLocale } = useLocaleContext();
  const lang = currentLocale?.split("_")[0].toUpperCase();

  return (
    <NavLink to={deal.link.startsWith("/") ? deal.link : `/${deal.link}`}>
      {imgErr ? (
        <div className={classes.imgError}>
          <div className={classes.frame}>
            <IconPhotoFilled size={"100%"} color="var(--ui-4)" />
          </div>
        </div>
      ) : (
        <img
          onError={() => setImgErr(true)}
          alt={deal.city?.[lang]}
          src={
            `https://${window.SERVER_DATA.REACT_APP_BUCKET_NAME}.` +
            `${window.SERVER_DATA.REACT_APP_BUCKET_PATH}/cities/` +
            `${deal.iata}.jpg`
          }
        />
      )}
      <div className={classes.destination}>
        <h2>{deal.city?.[lang]}</h2>
        <p>
          {getStringRes("cheap.travel.deals.item.flightsFrom")} {deal.price} €
        </p>
      </div>
    </NavLink>
  );
}

const listContainerVariants = { hidden: { opacity: 0 }, visible: { opacity: 1 } };

const listItemVariants = {
  hidden: { opacity: 0, scale: 0.8, y: -20 },
  visible: {
    y: 0,
    opacity: 1,
    scale: 1,
    transition: { duration: 0.33, ease: "backOut" },
  },
};

function DestinationGroup({ rows, cols, totalVisible, travelDeals }) {
  return (
    <motion.ul
      initial="hidden"
      animate="visible"
      variants={listContainerVariants}
      style={{
        gridTemplateColumns: `repeat(${cols}, 1fr)`,
        gridTemplateRows: `repeat(${rows}, 1fr)`,
      }}
      className={classes.dealsList}>
      {travelDeals.slice(0, totalVisible).map((deal) => (
        <motion.li
          whileHover={{ scale: 1.03 }}
          whileTap={{ scale: 0.95 }}
          variants={listItemVariants}
          initial="hidden"
          animate="visible"
          key={deal.id}>
          <Destination deal={deal} />
        </motion.li>
      ))}
    </motion.ul>
  );
}

function TopDestinations() {
  const { isMobile } = useDeviceContext();
  const { getStringRes } = useLocaleContext();
  const [categories, setFilterCat] = useState([]);
  const [activeFilter, setActiveFilter] = useState("");
  const [visibleItemNum, setVisibleItemNum] = useState(0);

  const getInitialItemNum = useCallback((col, row, total) => {
    return Math.min(col * row, total);
  }, []);

  const [travelDeals, setTravelDeals] = useState([]);
  const hasMoreOffers = travelDeals.length > visibleItemNum;
  const COL_NUM = Math.min(isMobile ? 2 : 5, travelDeals.length);
  const ROW_NUM = Math.min(isMobile ? 3 : 2, Math.ceil(travelDeals.length / COL_NUM));

  useEffect(() => {
    destinationsApi
      .getTravelDeals()
      .then((deals) => {
        setTravelDeals(deals.data);
        const availableFilters = [
          ...new Set(deals.data.map((deal) => deal.category).filter(Boolean)),
        ].sort();
        setFilterCat(availableFilters);
        setActiveFilter(availableFilters[0]);
      })
      .catch((err) => {
        console.log(err);
        return [];
      });
  }, []);

  useEffect(() => {
    setVisibleItemNum(getInitialItemNum(COL_NUM, ROW_NUM, travelDeals.length));
  }, [COL_NUM, ROW_NUM, travelDeals.length, getInitialItemNum]);

  const onClickFilterHandler = (selectedFilter) => {
    setActiveFilter(selectedFilter);
  };

  const onSeeMoreOffersHandler = () => {
    setVisibleItemNum((visible) => Math.min(visible + ROW_NUM * COL_NUM, travelDeals.length));
  };

  const onCollapseOffersHandler = () => {
    setVisibleItemNum(COL_NUM * ROW_NUM);
  };

  const filteredDeals = travelDeals.filter((deal) => {
    return activeFilter ? deal.category === activeFilter : true;
  });

  if (travelDeals.length === 0) {
    return null;
  }

  return (
    <section className={`${classes.deals} ${isMobile ? classes.mobile : classes.desktop}`}>
      <PaddedView>
        <h1>{getStringRes("cheap.travel.deals.title")}</h1>
        <p className={classes.desc}>{getStringRes("cheap.travel.deals.description")}</p>
        <div className={`${classes.container} ${classes.filterGroup}`}>
          {categories
            .filter((cat) => travelDeals.some((deal) => deal.category === cat))
            .map((cat) => (
              <Button
                key={cat}
                name={getStringRes(`cheap.travel.deals.filter.${cat}`)}
                fontclass={`${classes.filterButtonFont} ${
                  !isMobile && cat === activeFilter ? classes.active : ""
                }`}
                onClick={(e) => onClickFilterHandler(cat)}
                {...(cat === activeFilter ? { ternary: true } : { secondary: true })}
              />
            ))}
        </div>
        <DestinationGroup
          cols={COL_NUM}
          rows={ROW_NUM}
          totalVisible={visibleItemNum}
          travelDeals={filteredDeals}
        />
        {filteredDeals.length > ROW_NUM * COL_NUM && (
          <div style={{ textAlign: "center" }}>
            <Button
              name={
                hasMoreOffers
                  ? getStringRes("cheap.travel.deals.button.more")
                  : getStringRes("cheap.travel.deals.button.less")
              }
              opts={{ padding: "1rem 1.25rem", marginBottom: "1.5rem" }}
              onClick={hasMoreOffers ? onSeeMoreOffersHandler : onCollapseOffersHandler}
              secondary
            />
          </div>
        )}
      </PaddedView>
    </section>
  );
}

export default TopDestinations;
